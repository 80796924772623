import React, { useEffect, useState } from 'react';

const AnimatedText = ({ text }) => {
  const [displayedText, setDisplayedText] = useState('');

  useEffect(() => {
    let currentIndex = 0;
    setDisplayedText(''); // Reset displayedText when text changes
    const intervalId = setInterval(() => {
      if (currentIndex < text.length) {
        setDisplayedText((prev) => prev + text[currentIndex]);
        currentIndex++;
      } else {
        clearInterval(intervalId);
      }
    }, 20); // Adjust the speed of the animation here (milliseconds per letter)

    return () => clearInterval(intervalId);
  }, [text]);

  const formatText = (text) => {
    const boldPattern = /\*\*(.*?)\*\*/g;
    const parts = text.split(boldPattern);
    return parts.map((part, index) =>
      index % 2 === 1 ? <strong key={index}>{part}</strong> : part
    );
  };

  return (
    <div className="text-lg text-darkest-blue bg-white font-mulish rounded-lg p-4 shadow-lg whitespace-pre-wrap transition-all duration-300 ease-in-out hover:bg-lighter-blue hover:shadow-xl">
      {formatText(displayedText)}
    </div>
  );
};

export default AnimatedText;
