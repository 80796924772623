import React, { useState } from 'react';
import axios from 'axios';
import SideNav from './SideNav';
import DataTable from 'react-data-table-component';
import MenuIcon from '@mui/icons-material/Menu';
import Logo from "./assets/Logo.png";
import AnimatedText from './AnimatedText';

const {GoogleGenerativeAI} = require("@google/generative-ai");
const genAI = new GoogleGenerativeAI("AIzaSyC8VSPGQu2pyXz0vMHVoFTCMmFMBJ7nXPk")
const prompt1 = [`firstly give all the values present in first row and give insights for first row then 2nd then 3rd and so on
give insights after looking at the table data provided.
Achieving buybox prominence can significantly enhance sales and offtakes. 
Ensuring balanced stock levels to match offtakes prevents stockouts or overstock situations. 

also the heading for each row should be Day of that particular column
`]

function Insights() {
    const [results, setResults] = useState(null)
    const [columns, setColumns] = useState([]);
    const [summary, setSummary] = useState('');
    const [loading, setLoading] = useState(false);
    const [dataAvailable, setDataAvailable] = useState(false);

    const [drawerOpen, setDrawerOpen] = useState(false);
    const handleInsights = async () => {
        setResults([]);
        setColumns([]);
        setSummary('');
        setLoading(true);
        
        try {
            const response = await axios.post('https://flask-server-o7l2.onrender.com/api/insights', {});
            const { rows, fields } = response.data;
        
            console.log("*****");
            console.log(response.data);
            console.log(rows)
            console.log(columns)
            setResults(rows);
        setColumns(fields.map(field => ({
          name: field.name,
          selector: row => row[field.name],
          sortable: true
        })));
            setDataAvailable(true);

            const model = genAI.getGenerativeModel({ model: "gemini-pro" });
            const resp = await model.generateContent([prompt1[0], JSON.stringify(rows)]);
            const insights = resp.response.text();

            console.log(response.data)
            console.log("***")
            console.log(insights)

            if (rows.length > 0) {
                setSummary(insights);
            }
            else{
                setSummary('');
            }
      
                
            //   const insights = await generateInsights(preparedData);
            //   console.log("&&&")
            //   console.log(insights)
              
      
              // const model = genAI.getGenerativeModel({ model: "gemini-pro" });
              // const resp3 = await model.generateContent([prompt2[0], JSON.stringify(chartData)]);
              // const insights2 = resp3.response.text();
      
              // console.log(insights2)
            } catch (error) {
              console.error('Error fetching the data:', error);
              setLoading(false);
            }
            finally {
                setLoading(false); 
              }
          };
        
    // const generateInsights = async (preparedData) => {
    // const insights = [];

    // for (const data of preparedData) {
    //     const model2 = genAI.getGenerativeModel({ model: "gemini-pro" });
    //     const resp3 = await model2.generateContent([prompt2[0], JSON.stringify(data)]);
    //     insights.push(resp3.response.text());
    // }
    // console.log(insights)
    // return insights;
    // };

    const toggleDrawer = (open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
          return;
        }
        setDrawerOpen(open);
      };

  return (
    <div className="p-8 flex flex-col bg-[#e0fbfc] min-h-screen">
      <SideNav open={drawerOpen} toggleDrawer={toggleDrawer} />

      <div className="m-10">
        <div className="flex items-center mb-4 mt-8">
        <MenuIcon className="cursor-pointer" onClick={toggleDrawer(true)} />
          <img src={Logo} alt="Logo" className="h-9 w-54 mr-4" />
        </div>
        <h1 className="text-3xl font-extrabold text-primary mt-8 text-black font-mulish">Uncover E-commerce with Instant Insights</h1>
        <p className="text-base text-gray-600 mb-8 mt-4">
        Instant Insights empowers you to unlock valuable product data from e-commerce platforms with ease. Simply ask your question, and our intelligent system translates it into tailored data requests. Instant then retrieves insights efficiently and accurately, saving you time and effort, all to fuel your business growth.
        </p>
        <button
          onClick={handleInsights}
          disabled={loading}
          className="w-full mb-8 font-mulish bg-darkest-blue hover:bg-blue-900 text-white font-medium py-2 px-4 rounded focus:outline-none focus:ring-2 focus:ring-offset-2 focus:bg-blue-800"
        >
          {loading ? 'Loading Data...' : 'Show Results'}
        </button>
        {loading && <div className="flex justify-center mb-4"><span className="spinner-border spinner-border-sm"></span></div>}
        {!loading && dataAvailable && (
          <DataTable
            columns={columns}
            data={results}
            pagination
            highlightOnHover
            pointerOnHover
            className="rounded-lg shadow-lg w-full"
            customStyles={{
              headCells: {
                style: {
                  fontSize: '14px',
                  fontWeight: 'bold',
                  backgroundColor: '#f8f8f8',
                  color: '#333',
                },
              },
              rows: {
                style: {
                  fontSize: '13px',
                },
                highlightOnHoverStyle: {
                  backgroundColor: '#f1f5f9',
                  cursor: 'pointer',
                },
              }
            }}
          />
        )}
        {!loading && dataAvailable && summary && (
          <>
            <h2 className="text-2xl font-bold text-darkest-blue mt-8 mb-4">Insights:</h2>
            <AnimatedText text={summary} />
          </>
        )}
        </div>
    </div>
  )
}

export default Insights;
