// SideNav.js
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Drawer, List, ListItem, ListItemIcon, ListItemText, Divider } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import InsightsIcon from '@mui/icons-material/Insights';

const SideNav = ({ open, toggleDrawer }) => {
    const navigate = useNavigate();
  return (
    <Drawer anchor="left" open={open} onClose={toggleDrawer(false)}>
      <div
        role="presentation"
        onClick={toggleDrawer(false)}
        onKeyDown={toggleDrawer(false)}
      >
        <List>
          <ListItem button onClick={() => navigate('/Home')}>
            <ListItemIcon><HomeIcon /></ListItemIcon>
            <ListItemText primary="Home" />
          </ListItem>
          <ListItem button onClick={() => navigate('/Sales')}>
            <ListItemIcon><QueryStatsIcon /></ListItemIcon>
            <ListItemText primary="Queries" />
          </ListItem>
          <ListItem button onClick={() => navigate('/Insights')}>
            <ListItemIcon><InsightsIcon /></ListItemIcon>
            <ListItemText primary="Insights" />
          </ListItem>
        </List>
        <Divider />
      </div>
    </Drawer>
  );
};

export default SideNav;
