import "./index.css";
import Login from "./Login";
import Home from "./Home";
import Sales from "./Sales";
import Insights from "./Insights";
import { Route, Routes } from "react-router-dom";


function App() {
  return (
    <>
      <Routes>
        <Route path="/" element={<Login/>}/>
        <Route path="/home" element={<Home/>}/>
        <Route path="/Sales" element={<Sales/>}/>
        <Route path="/insights" element={<Insights/>}/>
      </Routes>
    </>
  );
}

export default App;