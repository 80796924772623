import React, { useState } from 'react';
import axios from 'axios';
import SideNav from './SideNav';
import MenuIcon from '@mui/icons-material/Menu';
import Logo from "./assets/Logo.png";
import AnimatedText from './AnimatedText';
import { Line } from 'react-chartjs-2';

import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    TimeScale
  } from 'chart.js';
  
  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    TimeScale
  );

  const {GoogleGenerativeAI} = require("@google/generative-ai");
  const genAI = new GoogleGenerativeAI("AIzaSyC8VSPGQu2pyXz0vMHVoFTCMmFMBJ7nXPk")
  const prompt2 = [`give insights after looking at the chart data provided.`]

function Sales() {
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const getRandomColor = () => {
        const letters = '0123456789ABCDEF';
        let color = '#';
        for (let i = 0; i < 6; i++) {
          color += letters[Math.floor(Math.random() * 16)];
        }
        return color;
      };
    
        const [chartData, setChartData] = useState([]);
    
        const handleInsights = async () => {
          setLoading(true);
            try {
              const predictionResponse = await axios.post('https://flask-server-o7l2.onrender.com/api/predict', {});
              const responseData = predictionResponse.data.data;
        
              console.log("*****");
              console.log(responseData);
        
              const preparedData = responseData.map((chart, index) => {
                const pincodes = [...new Set(chart.map(item => item.pincode))];
                const datasets = pincodes.map(pincode => {
                  const filteredData = chart.filter(item => item.pincode === pincode);
                  return {
                    label: `Pincode ${pincode}`,
                    data: filteredData.map(item => ({
                      x: new Date(item.date_column), // Ensure date is in proper format
                      y: item.orderAmt
                    })),
                    //data: filteredData.map(item => ({ x: item.date_column, y: item.orderAmt })),
                    borderColor: getRandomColor(), // Function to get a random color
                    backgroundColor: 'rgba(0,0,0,0)',
                    borderWidth: 1,
                    fill: false,
                  };
                });
      
                return {
                  webPid: chart[0].web_pid,
                  labels: chart.map(item => item.date_column),
                  datasets: datasets,
                  xMin: Math.min(...chart.map(item => new Date(item.date_column))),
                  xMax: Math.max(...chart.map(item => new Date(item.date_column)))
                };
              });
        
              const insights = await generateInsights(preparedData);
              console.log("&&&")
              console.log(insights)
              setChartData(preparedData.map((data, index) => ({
                ...data,
                insights: insights[index]
              })));
      
              // const model = genAI.getGenerativeModel({ model: "gemini-pro" });
              // const resp3 = await model.generateContent([prompt2[0], JSON.stringify(chartData)]);
              // const insights2 = resp3.response.text();
      
              // console.log(insights2)
            } catch (error) {
              console.error('Error fetching the data:', error);
              setLoading(false);
            }finally {
              setLoading(false); 
            }
          };
        
          const generateInsights = async (preparedData) => {
            const insights = [];
        
            for (const data of preparedData) {
              const model2 = genAI.getGenerativeModel({ model: "gemini-pro" });
              const resp3 = await model2.generateContent([prompt2[0], JSON.stringify(data)]);
              insights.push(resp3.response.text());
            }
            console.log(insights)
            return insights;
          };

    const toggleDrawer = (open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
          return;
        }
        setDrawerOpen(open);
      };

  return (
    <div className="p-8 flex flex-col bg-[#e0fbfc] min-h-screen">
      <SideNav open={drawerOpen} toggleDrawer={toggleDrawer} />

      <div className="m-10">
        <div className="flex items-center mb-4 mt-8">
        <MenuIcon className="cursor-pointer" onClick={toggleDrawer(true)} />
          <img src={Logo} alt="Logo" className="h-9 w-54 mr-4" />
        </div>
        <h1 className="text-3xl font-extrabold text-primary mt-8 text-black font-mulish">Uncover E-commerce with Instant Insights</h1>
        <p className="text-base text-gray-600 mb-8 mt-4">
        Instant Insights empowers you to unlock valuable product data from e-commerce platforms with ease. Simply ask your question, and our intelligent system translates it into tailored data requests. Instant then retrieves insights efficiently and accurately, saving you time and effort, all to fuel your business growth.
        </p>
        <button
          onClick={handleInsights}
          disabled={loading}
          className="w-full mb-8 font-mulish bg-darkest-blue hover:bg-blue-900 text-white font-medium py-2 px-4 rounded focus:outline-none focus:ring-2 focus:ring-offset-2 focus:bg-blue-800"
        >
          {loading ? 'Loading Data...' : 'Show Insights'}
        </button>
        
        <div className="flex flex-wrap -mx-8">
    {chartData.map((data, index) => (
      <div key={index} className="w-full md:w-1/2 px-1">
        <div className="bg-white rounded shadow-md p-4">
          <h3 className="text-lg font-bold mb-2">{`${data.webPid}`}</h3>
          <Line
            data={data}
            options={{
              layout: {
                padding: {
                  top: 4, // Increase top padding for the chart area
                  bottom: 4 // Increase bottom padding for the chart area
                }
              },
              scales: {
                x: {
                  type: 'time', // Use time scale for x-axis
                  time: {
                    unit: 'month',
                    tooltipFormat: 'MMM yyyy' // Adjust the time unit as needed
                  },
                  min: data.xMin,
                  max: data.xMax,
                  title: {
                    display: true,
                    text: 'Date'
                  }
                },
                y: {
                  title: {
                    display: true,
                    text: 'Order Amount'
                  }
                }
              },
              plugins: {
                legend: {
                  display: true,
                  position: 'top',
                  labels: {
                    boxWidth: 12, // Decrease legend box width
                    fontSize: 10 // Decrease legend font size
                  }
                }
              },
            }}
          />
          <p className="mt-4 text-sm text-gray-600"><AnimatedText text={data.insights}/></p>
        </div>
      </div>
    ))}
  </div>
        </div>
    </div>
  )
}

export default Sales;
